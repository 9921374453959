:root {
  --lime-green: #35fd06;
  --light-lime-green: #b8f992;
  --yellow: #fff4aa;
  --coral: #ff7575;
  --purple: #bcc1fe;
  --pink: #f1b6c6;
  --extra-light-gray: #e0e0e0;
  --light-gray: #808080;
  --med-light-gray: #5C5A5A;
  --med-gray: #534f4f;
  --dark-gray: #3a3939;
  --background-lighter: #272626;
  --background-darker: #161515;
  --black: #000000;
}

* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: var(--lime-green);
  font-family: Courier, 'Courier New', monospace;
  background: linear-gradient(var(--background-lighter), 90%, var(--background-darker));
}

.main {
  flex: 1;
  width: min(890px, calc(100vw - 25px));
  margin: 0 auto;
}

h1 {
  font-weight: 300;
}

h2 {
  color: var(--coral);
  font-size: 25px;
  font-weight: 300;
}

a {
  text-decoration: none;
  color: var(--lime-green);
}

a:hover {
  color: var(--light-lime-green);
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

button:disabled {
  cursor: not-allowed;
}

button:hover {
  color: var(--light-lime-green);
}

#contribute-example {
  display: block;
  text-align: right;
  margin-top: 20px;
  font-size: 20px;
}

.blink {
  animation: opacity 1s step-end infinite;
  opacity: 1;
}
@keyframes opacity {
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.dropdown-container {
  position: relative;
  display: inline-flex;
}

.select-lang-options {
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 25px;
  background-color: var(--light-gray);
  list-style: none;
  margin-top: 2px;
  border-radius: 5px;
  z-index: 1;
  max-height: 300px; /* limit size of dropdown box */
  overflow-y: scroll;
}

.select-lang-options::-webkit-scrollbar {  /* scrollbar track */
  width: 12px;
  background-color: var(--light-gray); /* blend in with rest of dropdown */
}

.select-lang-options::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--med-light-gray);
}

.select-lang-options li {
  padding-top: 8px;
  padding-right: 20px;
  padding-bottom: 8px;
  padding-left: 20px;
  cursor: pointer;
  border-radius: 5px;
}

/* when hovering over list elements, flip the background to pink and text to black */
.select-lang-options li:hover {
  background: var(--pink);
  color: var(--black);
}

.slide-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  border: 5px solid var(--purple);
  border-radius: 10px;
  background-color: var(--black);
  font-size: 16px;
  padding: 10px;
}

.spoiler-box {
  border-radius: 15px;
  position: relative;
  margin-top: 18px;
}

.spoiler-click-msg {
  color: var(--extra-light-gray);
  font-family: Verdana, Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 800;
  opacity: 0.25;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.explanation {
  white-space: pre-wrap;
  padding: 16px;
}

.explanation.showExplanation code {
  color: var(--yellow);
  font-family: Courier, 'Courier New', monospace;
}

.credit {
  color: var(--light-gray);
  padding: 10px 5px 2px 6px;
  font-size: 17px;
}

.slide-nav-container {
  width: 100%;
  display: block;
  position: relative;
  text-align: center;
  margin-top: 10px;
  padding: 7px 0;
}

.prev-button, .next-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 20px;
  height: 25px;
  top: 12px;
}

.prev-button {
  left: 50px;
}

.next-button {
  right: 50px;
}

.prev-next-icon {
  font-size: 45px;
  color: var(--lime-green);
}

.prev-next-icon:hover {
  color:  var(--light-lime-green);
}

button:disabled .prev-next-icon {
  color: var(--light-gray);
}

.middle-of-navbar {
  font-size: 30px;
  color: var(--light-gray);
}

.run-and-copy-container {
  position: relative;
  display: inline-block;
}

.play-icon-a {
  margin-left: 20px;
}

.play-icon {
  color: var(--lime-green);
  font-size: 20px;
  position: relative;
  top: -2px;
}

.play-icon:hover {
  color: var(--light-lime-green);
}

.copy-link-icon-button {
  margin-left: 20px;
}

.copy-link-icon {
  color: var(--lime-green);
  font-size: 15px;
  position: relative;
  top: -4px;
}

button:hover .copy-link-icon {
  color: var(--light-lime-green);
}

.copy-success-msg {
  background-color: var(--med-gray);
  color: var(--light-lime-green);
  font-size: 13px;
  position: absolute;
  top: 100%;
  left: 40%;
  padding: 4px 8px;
  border-radius: 5px;
  opacity: 0.9;
  z-index: 1;
}

.footer-icon-container {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  background-color: var(--background-darker);
}

.footer-icon {
  margin: 1.4rem 1rem 2rem 1rem;
  color: var(--light-gray);
}

.footer-icon:hover {
  color: var(--light-lime-green);
}
